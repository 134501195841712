import request from '@/api/request'

/**
 * 获取列表
 */
export function getModelList(query) {
    return request({
        url: '/system/model/list',
        method: 'get',
        params: query
      });
}

/**
 * 根据id获取详情
 */
export function getModelDetail(id) {
  return request({
      url: '/system/model/' + id,
      method: 'get'
    });
}

